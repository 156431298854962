@import '../../App';

.modal {

  .dialog-header {
    padding: 20px 18px;
    min-width: 550px;
  }

  .modal-close {
    position: absolute !important;
    top: 11px;
    right: 5px;
  }
  .dialog-content {
    min-height: 300px;
  }
}
