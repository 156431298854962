@import './App';
html {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $black25;
    border-radius: 2px;
  }
}

/* Typography */
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  margin: 0;
  letter-spacing: 0.04em;
}

h1 {
  font-weight: 300;
}

li {
  list-style: none;
}

.filter-card .MuiInput-underline:before {
  content: '' !important;
}

label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.grid-dropdown .MuiBox-root.MuiBox-root-19 {
  height: 40px !important;
  margin: 0 !important ;
  margin-top: 15px !important;
  padding: 0 !important;
}

[hidden],
template {
  display: none !important;
}

body {
  background: $lightversion;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;

  /* Dashboard Main Content Area*/
  .main-content {
    padding-top: 80px;
    width: calc(100% - 242px);
    margin-left: 242px;
    padding-bottom: 50px;
  }

  /* Main content - Page header */
  .page-header {
    margin: 10px 0px;
    padding-top: 15px;
  }

  /* Start: Badge */
  .badge-outlined {
    background: $transparent;
    padding: 3px 8px;
    text-transform: uppercase;
    line-height: 12px;
    font-weight: 400;
    font-size: 11px;
    border-radius: 4px;
    display: inline-block;
  }
  .badge-success {
    color: $green100;
    border: 1px solid $green100;
  }
  .badge-warning {
    color: $orange100;
    border: 1px solid $orange100;
  }
  .badge-info {
    color: $blue75;
    border: 1px solid $blue75;
  }

  .approver-emails {
    text-transform: none;
    font-size: 12px;
  }

  .leave-status-badge {
    text-transform: none;
    font-size: 12px;

    &:nth-child(2),
    &:nth-child(3) {
      border: none;
      color: $black90;
    }
  }
  /* End: Badge */

  /* Start:  Material Icon */
  .icon-wrapper svg {
    padding: 2px;
    width: 28px;
    height: 28px;
    margin: 0px 2px;
  }
  .icon-wrapper .icon-success {
    color: $green100;
    font-size: 14px;
  }
  .icon-wrapper .icon-warning {
    color: $orange100;
  }
  .icon-wrapper .icon-danger {
    color: $red100;
  }
  .icon-wrapper .icon-info {
    color: $blue75;
  }
  .icon-wrapper .icon-secondary {
    color: $black75;
  }
  /* End: Material Icon */

  /* Start: Avatar */
  .avatar-text {
    padding: 0px 10px;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
    display: inline-block;
    background: $blue75;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .avatar-logo {
    max-width: 100px;
    font-size: 12px;
    text-align: center;
    max-height: 100px;
  }

  .mt-36 {
    margin-top: 36px;
  }

  /* Breadcrumb */
  .MuiBreadcrumbs-root li {
    font-size: 14px;
  }
  .MuiBreadcrumbs-root a {
    color: $blue75;
  }

  .MuiBreadcrumbs-root a {
    color: $black75;
  }

  /* Tabs */
  .dashboard-tab {
    margin: 25px 0px;

    .tab-link {
      margin-right: 30px;
      text-decoration: none;
      padding: 10px 0px;
      background: $transparent;
      color: $black90;

      &:hover {
        color: $black50;
      }

      &.active {
        border-bottom: 3px solid $yellow100;
      }
    }
  }

  .text-muted {
    color: $black25;
  }

  p {
    word-break: break-all !important;
  }

  // common
  .align-center {
    text-align: center;
  }

  .error {
    color: $red90;
    font-size: 14px;
    line-height: 20px !important;
  }

  .right-nav {
    position: relative;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 81px;

    .logged-user-name {
      margin-bottom: 8px;
      font-size: 12px;
    }
  }

  .navbar-toggler {
    display: none;

    .navbar-brand {
      max-width: 130px;
      margin-left: 10px;

      img {
        width: 89%;
        height: 80%;
      }
    }
  }

  .sidebar-wrapper.collapsed {
    margin-left: 0px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

    .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .brand-link {
        padding: 6px 0px !important;
        width: 75%;
      }

      img {
        width: 89%;
      }

      .sidebar-close {
        color: $yellow100;
        padding-top: 8px;
      }
    }
  }

  .navbar-toggler-link {
    color: $black90;
    padding: 0px 3px;
    line-height: 56px;
    height: 39px;
    width: 39px;
    cursor: pointer;

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .sidebar-close {
    display: none;
  }

  @media (min-width: 991px) {
    .sidebar-wrapper {
      margin-left: 0px !important;
    }
  }

  @media (max-width: 991px) {
    .multiple-file-upload {
      width: 70% !important;
    }

    .multiple-file-label.file-label {
      width: 73% !important;
    }

    .sidebar-wrapper {
      margin-left: -240px;

      .sidebar-close {
        display: block;
      }
    }

    .header-wrapper {
      width: calc(100% - 0px);
    }

    .main-content {
      width: calc(100% - 0px);
      margin-left: 0px;
    }

    .navbar-toggler {
      display: flex;
    }

    .link-button-wrapper {
      text-align: right !important;
    }
  }
}

@media (max-width: 700px) {
  .multiple-file-upload {
    width: 90% !important;
  }
  .multiple-file-label.file-label {
    width: 70% !important;
  }
}

.file-label {
  padding: 10px 20px;
  border: 1px solid #d5d5d5;
  background: #ececec;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  display: inline-block;
  display: inline-flex;
  align-items: center;
}

.file-section {
  position: relative;
  display: inline-block;
}

.delete-file {
  position: absolute;
  right: -11px;
  top: -9px;
  cursor: pointer;
  & svg {
    color: #ad0909;
    background: white;
    border-radius: 50%;
    margin-right: 5px;
  }
}

a.file-label {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}

.view-button {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.landing-quark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.profile-icon .profile-text {
  border-radius: 50%;
  border: 1px solid $yellowp1;
  height: 40px;
  color: $white1;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: $yellowp1;
}
.avatar-logo.profile-logo {
  width: 50px;
  height: 50px;
}

small.text-success {
  color: $blueb100;
  font-weight: 600;
}
